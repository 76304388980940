import CountUp from "react-countup";
import { HiDocumentCheck } from "react-icons/hi2";
import { FaUsers } from "react-icons/fa";

const AstaNumber = () => {
	return (
		<div className="bg-violet text-white w-4/5 md:w-3/4 mx-auto rounded-3xl flex flex-col md:flex-row gap-8 md:gap-4 lg:gap-16 px-4 xl:px-16 py-16 justify-center items-center">
			<div className="flex flex-col gap-2 items-center md:items-start">
				<div className="font-semibold text-2xl md:text-xl lg:text-3xl text-center md:text-start">
					ASTA DALAM ANGKA
				</div>
				<div className="text-sm lg:text-base text-center md:text-start">
					Telah Dipercaya Oleh Banyak Pelanggan
				</div>
			</div>
			<div className="flex flex-col md:flex-row gap-4 xl:gap-8 items-center">
				<div className="flex gap-2 xl:gap-4 items-center">
					<HiDocumentCheck className="text-6xl" />
					<div className="flex flex-col gap-1">
						<CountUp
							end={11000}
							suffix="+"
							duration={3}
							className="text-lg lg:text-2xl font-semibold"
						/>
						<div className="text-sm lg:text-base">Pesanan Selesai</div>
					</div>
				</div>
				<div className="flex gap-2 xl:gap-4 items-center">
					<FaUsers className="text-6xl" />
					<div className="flex flex-col gap-1">
						<CountUp
							end={999}
							suffix="+"
							duration={3}
							className="text-2xl font-semibold"
						/>
						<div className="text-sm lg:text-base">Pelanggan Puas</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AstaNumber;
