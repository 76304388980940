import Foto1 from "../Image/keunggulan/Foto1.png";
import Foto2 from "../Image/keunggulan/Foto2.png";
import Foto3 from "../Image/keunggulan/Foto3.png";
import Foto4 from "../Image/keunggulan/Foto4.png";
import Foto5 from "../Image/keunggulan/Foto5.png";
import Foto6 from "../Image/keunggulan/Foto6.png";

const content = [
	{
		image: Foto1,
		title: "One-Stop Printing Service",
		desc: "Mulai dari konsultasi, cetak, potong, finishing, sampai tiba di lokasi klien.",
	},
	{
		image: Foto2,
		title: "Free Konsultasi",
		desc: "Mulai dari pilihan kertas dan bahan sampai kebutuhan advertising.",
	},
	{
		image: Foto3,
		title: "Mesin/Alat Cetak Lengkap",
		desc: "Memiliki mesin, alat cetak, dan alat pendukung yang lengkap, agar dapat memenuhi  kebutuhan pelanggan secara end to end.",
	},
	{
		image: Foto4,
		title: "Pemesanan Mudah",
		desc: "Pemesanan cepat, tanggap, dan langsung via Whatsapp. Cukup kirimkan desain dan jelaskan kebutuhan klien.",
	},
	{
		image: Foto5,
		title: "Jangkauan Luas",
		desc: "Pengiriman ke berbagai wilayah di Indonesia untuk menjangkau klien.",
	},
	{
		image: Foto6,
		title: "10.000+ Pesanan Selesai",
		desc: "Telah dipercaya oleh banyak klien dari berbagai kalangan",
	},
];

const Keunggulan = () => {
	return (
		<div className="w-5/6 md:w-3/4 mx-auto my-16" id="keunggulan">
			<div className="flex flex-col sm:flex-row gap-1 md:gap-2 font-semibold text-lg md:text-xl lg:text-3xl justify-center text-center sm:text-start">
				<div className="text-grey">Kenapa Harus</div>
				<div className="text-violet">Cetak di ASTA?</div>
			</div>
			<div className="flex flex-wrap gap-2 md:gap-8 justify-center mt-6">
				{content.map((i) => (
					<div className="shadow-lg rounded-3xl flex flex-col gap-4 p-4 w-full md:w-1/3 lg:w-1/4 my-2 bg-white">
						<img
							src={i.image}
							alt="content"
							className="w-full self-center rounded-3xl"
						/>
						<div className="font-bold text-sm lg:text-base">{i.title}</div>
						<div className="text-xs lg:text-sm">{i.desc}</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default Keunggulan;
