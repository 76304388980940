import AstaLogo from "../Image/logo/asta-logo.png";
import AstaMap from "../Image/asta-map.png";
import AstaPartner from "../Image/asta-partners.svg";
import AstaPartnerMobile from "../Image/asta-partners-mobile.svg";
import { motion } from "framer-motion";
import { FaLocationDot } from "react-icons/fa6";
import { AiFillRightCircle } from "react-icons/ai";
import { IoDocumentTextSharp } from "react-icons/io5";

const TentangKami = () => {
	return (
		<div className="w-5/6 md:w-3/4 mx-auto my-16" id="tentang-kami">
			<div className="flex gap-1 font-semibold text-lg md:text-xl lg:text-3xl justify-center text-start">
				<div className="text-grey">Apa itu</div>
				<div className="text-violet">ASTA?</div>
			</div>
			<div className="flex flex-col md:flex-row justify-center mt-8 md:mt-16 gap-8 items-center">
				<div className="w-2/3 md:w-1/4 h-fit rounded-2xl bg-white shadow-md flex justify-center items-center p-8">
					<img src={AstaLogo} alt="logo" className="w-40 self-center" />
				</div>
				<div className="text-sm lg:text-base text-justify w-full md:w-2/3">
					CV ANDALAN SEMESTA TATA AKSARA (ASTA) adalah perusahaan percetakan dan
					advertising. Kami adalah one-stop printing service yang mampu memenuhi
					kebutuhan pelanggan dari awal sampai akhir, mulai dari dari
					konsultasi, cetak, potong, finishing, sampai tiba di lokasi pelanggan.
					Selain itu, Kami telah melayani berbagai macam jenis cetakan, dan juga
					telah melakukan kerja sama dengan berbagai pihak yang sifatnya
					perseorangan maupun perusahaan. Oleh karena itu, CV ASTA terus
					berupaya meningkatkan kualitas pelayanan agar jumlah serta tingkat
					kepuasan pelanggan dapat terus meningkat.
				</div>
			</div>
			<div className="flex flex-col md:flex-row justify-center mt-8 md:mt-16 items-center bg-white md:pr-4 rounded-2xl shadow-md mx-auto w-fit">
				<img
					src={AstaMap}
					alt="map"
					className="rounded-t-xl md:rounded-t-none md:rounded-tl-xl md:rounded-bl-xl w-full md:w-80 lg:w-96"
				/>
				<div className="flex flex-col justify-center gap-4 lg:gap-8 mx-4 my-8 md:my-0">
					<div className="flex flex-col gap-2">
						<div className="flex gap-1 text-base">
							<div className="font-bold text-violet">Dimana sih ASTA itu?</div>
						</div>
						<div className="text-justify text-sm">
							JL. H. IPIN No. 27, Kel. Pondok Labu, Kec. Cilandak, Kota Adm.
							Jakarta Selatan, Prop. DKI Jakarta
						</div>
						<motion.a
							whileTap={{ scale: 0.9 }}
							className="flex p-3 bg-salmon text-white rounded-2xl gap-1 items-center justify-between w-60"
							href="https://maps.app.goo.gl/8NkTWe5iGWX83XNW8"
							target="_blank"
						>
							<div className="flex gap-1 items-center">
								<FaLocationDot className="text-xl" />
								<div className="font-semibold text-sm">Cek Lokasi</div>
							</div>
							<AiFillRightCircle className="text-xl" />
						</motion.a>
					</div>
					<div className="flex flex-col gap-2">
						<div className="flex flex-col sm:flex-row md:flex-col lg:flex-row gap-1 text-base justify-start items-start">
							<div className="font-bold text-violet text-start">
								Ingin tahu informasi price list dan lainnya?
							</div>
						</div>
						<motion.a
							whileTap={{ scale: 0.9 }}
							className="flex p-3 bg-salmon text-white rounded-2xl gap-1 items-center justify-between w-60"
							href=""
							target="_blank"
						>
							<div className="flex gap-1 items-center">
								<IoDocumentTextSharp className="text-xl" />
								<div className="font-semibold text-sm">ComPro & Pricelist</div>
							</div>
							<AiFillRightCircle className="text-xl" />
						</motion.a>
					</div>
				</div>
			</div>
			<div className="flex gap-1 font-semibold text-lg md:text-xl lg:text-3xl justify-center text-start mt-12 md:mt-16">
				<div className="text-grey">Klien</div>
				<div className="text-violet">Kami</div>
			</div>
			<img
				src={AstaPartner}
				alt="partner"
				className="hidden md:flex w-full my-4 md:my-8 drop-shadow-md"
			/>
			<img
				src={AstaPartnerMobile}
				alt="partner"
				className="flex md:hidden w-full my-4 md:my-8 drop-shadow-md"
			/>
		</div>
	);
};

export default TentangKami;
