import AstaPesan from "../Image/asta-pesan.png";
// import BgPattern from "../Image/bg-pattern.svg";
// import BgPatternMobile from "../Image/bg-pattern-mobile.svg";
import { motion } from "framer-motion";

const howTo = [
	{
		no: 1,
		step: "Kirim desain dan Pesan via WA/Email",
	},
	{
		no: 2,
		step: "Proses produksi percetakan",
	},
	{
		no: 3,
		step: "Pelunasan pembayaran",
	},
	{
		no: 4,
		step: "Pengiriman atau pengambilan pesanan",
	},
	{
		no: 5,
		step: "Pesanan Anda sampai di lokasi",
	},
];

const Pesan = () => {
	return (
		<>
			<div
				className="bg-white w-5/6 md:w-fit lg:w-3/4 mx-auto rounded-3xl mt-16 py-6 px-2 md:py-8 md:px-8 shadow-md"
				id="pesan"
			>
				<div className="flex gap-1 md:gap-2 font-semibold text-base md:text-xl lg:text-3xl justify-center text-start">
					<div className="text-violet">Gimana Sih Cara Order Di</div>
					<div className="text-grey">ASTA?</div>
				</div>
				<div className="flex flex-col md:flex-row gap-2 lg:gap-8 mt-2 md:mt-8 lg:mt-12 justify-center items-center">
					<img
						src={AstaPesan}
						alt="logo"
						className="w-60 lg:w-72 self-center"
					/>
					<div className="flex flex-col gap-6">
						{howTo.map((i) => (
							<div className="flex gap-2 items-center">
								<div className="bg-salmon text-white rounded-full flex justify-center items-center w-6 h-6 lg:w-8 lg:h-8 text-xs lg:text-base">
									{i.no}
								</div>
								<div className="text-xs lg:text-base">{i.step}</div>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className="relative bg-violet w-5/6 md:w-3/4 mx-auto rounded-3xl my-16 shadow-md px-8 py-14 text-white flex flex-col justify-center items-center gap-4 md:gap-6">
				<div className="flex flex-col gap-2">
					<div className="text-base md:text-lg">Punya Kebutuhan</div>
					<div className="text-2xl md:text-4xl font-bold">
						Cetak dan Promosi?
					</div>
				</div>
				<motion.a
					whileHover={{ scale: 1.1 }}
					whileTap={{ scale: 0.9 }}
					className="flex py-2 px-8 bg-salmon text-white rounded-2xl items-center justify-center"
					href="https://wa.me/6285921923982"
					target="_blank"
				>
					<div className="font-semibold text-sm md:text-base">
						Pesan Sekarang
					</div>
				</motion.a>
			</div>
		</>
	);
};

export default Pesan;
