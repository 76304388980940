import AstaNumber from "./Components/AstaNumber";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import HeroSection from "./Components/HeroSection";
import Keunggulan from "./Components/Keunggulan";
import Layanan from "./Components/Layanan";
import Mesin from "./Components/Mesin";
import Pesan from "./Components/Pesan";
import TentangKami from "./Components/TentangKami";
import "./Styles/App.css";

function App() {
	return (
		<div className="App bg-gray-50 h-fit">
			<div className="fixed top-0 z-40 w-full bg-white shadow-lg">
				<Header />
			</div>
			<div className="w-full py-12"></div>
			<HeroSection />
			<AstaNumber />
			<Layanan />
			<Mesin />
			<Keunggulan />
			<TentangKami />
			<Pesan />
			<Footer />
		</div>
	);
}

export default App;
