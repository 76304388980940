import LayananBuku from "../Image/layanan/01-Banner-Cetak-Buku.png";
import LayananKantor from "../Image/layanan/02-Banner-Kebutuhan-Kantor.png";
import LayananMerch from "../Image/layanan/03-Banner-Pembuatan-Merch.png";
import LayananMedPromosi from "../Image/layanan/04-Banner-Media-Promosi.png";
import LayananUndangan from "../Image/layanan/05-Banner-Cetak-Undangan.png";
import LayananPackaging from "../Image/layanan/06-Banner-Packaging.png";
import LayananSeminar from "../Image/layanan/07-Banner-Seminar-Kit.png";

import IconBuku from "../Image/icon/buku.png";
import IconKantor from "../Image/icon/kantor.png";
import IconMerch from "../Image/icon/merch.png";
import IconMedPromosi from "../Image/icon/promosi.png";
import IconUndangan from "../Image/icon/undangan.png";
import IconPackaging from "../Image/icon/packaging.png";
import IconSeminar from "../Image/icon/seminar.png";
import { useEffect, useState } from "react";

const contentLayanan = [
	{
		id: 1,
		title: "Cetak Buku",
		icon: IconBuku,
		content: LayananBuku,
	},
	{
		id: 2,
		title: "Kebutuhan Kantor",
		icon: IconKantor,
		content: LayananKantor,
	},
	{
		id: 3,
		title: "Souvenir Merchandise",
		icon: IconMerch,
		content: LayananMerch,
	},
	{
		id: 4,
		title: "Media Promosi",
		icon: IconMedPromosi,
		content: LayananMedPromosi,
	},
	{
		id: 5,
		title: "Cetak Undangan",
		icon: IconUndangan,
		content: LayananUndangan,
	},
	{
		id: 6,
		title: "Pembuatan Packaging",
		icon: IconPackaging,
		content: LayananPackaging,
	},
	{
		id: 7,
		title: "Seminar Kit",
		icon: IconSeminar,
		content: LayananSeminar,
	},
];

const Layanan = () => {
	const [layananActive, setLayananActive] = useState(1);
	const [dataActive, setDataActive] = useState(LayananBuku);

	useEffect(() => {
		const data = contentLayanan.find((i) => {
			return i.id === layananActive;
		});

		setDataActive(data.content);
	}, [layananActive]);

	return (
		<div className="flex-flex-col my-16 mx-auto w-4/5 md:w-3/4" id="layanan">
			<div className="flex gap-1 md:gap-2 font-semibold text-xl lg:text-3xl justify-center text-center md:text-start">
				<div className="text-grey">ASTA</div>
				<div className="text-violet">Bisa Cetak Apa Aja Sih?</div>
			</div>
			<div className="flex flex-col lg:flex-row gap-8 justify-center items-center mt-8">
				<div className="flex flex-row lg:flex-col gap-4 justify-start w-full lg:w-1/3 max-h-96 xl:max-h-none overflow-y-hidden lg:overflow-y-scroll xl:overflow-y-hidden overflow-x-scroll lg:overflow-x-hidden py-2 sm:py-4 px-2">
					{contentLayanan.map((i) => (
						<div
							className={`shadow-md flex gap-2 lg:gap-4 items-center pr-4 lg:pr-8 rounded-2xl cursor-pointer p-2 ${
								i.id === layananActive ? "bg-salmon" : "bg-white"
							}`}
							onClick={() => setLayananActive(i.id)}
						>
							<div
								className={`bg-salmon rounded-2xl max-w-24 h-10 sm:h-14 lg:h-16 flex items-center justify-center`}
							>
								<img
									src={i.icon}
									alt="icon-layanan"
									className="max-w-8 sm:max-w-12 lg:max-w-16"
								/>
							</div>
							<div
								className={`font-semibold text-wrap text-xs sm:text-sm lg:text-base px-1 lg:px-2 text-start ${
									i.id === layananActive ? "text-white" : "text-grey"
								}`}
							>
								{i.title}
							</div>
						</div>
					))}
				</div>
				<img
					src={dataActive}
					alt="content-layanan"
					className="w-full lg:w-1/2 rounded-2xl shadow-lg"
				/>
			</div>
		</div>
	);
};

export default Layanan;
