import TerBanner from "../Image/mesin/tersier-banner.svg";
import MainBanner from "../Image/mesin/main-banner.svg";
import MainBannerTablet from "../Image/mesin/main-banner-tablet.svg";
import MainBannerMobile from "../Image/mesin/main-banner-mobile.svg";
import Secbanner from "../Image/mesin/sec-banner.svg";
import SecbannerTablet from "../Image/mesin/sec-banner-tablet.svg";

const Mesin = () => {
	return (
		<div className="flex-flex-col my-16 mx-auto w-4/5 md:w-3/4">
			<div className="flex gap-4 justify-center md:justify-between">
				<div className="flex flex-col gap-1 font-semibold text-xl lg:text-3xl justify-center text-center md:text-start">
					<div className="text-grey">ASTA Punya Mesin</div>
					<div className="text-violet">atau Alat Apa Aja Sih?</div>
				</div>
				<img
					src={TerBanner}
					alt="ter-banner"
					className="w-1/2 hidden md:flex"
				/>
			</div>
			<div className="flex flex-col lg:flex-row justify-center items-center w-full mx-auto mt-8 gap-4">
				<div className="w-full lg:w-2/3">
					<img
						src={MainBanner}
						alt="main-banner"
						className="hidden lg:flex w-auto"
					/>
					<img
						src={MainBannerTablet}
						alt="main-banner"
						className="hidden sm:flex lg:hidden w-auto"
					/>
					<img
						src={MainBannerMobile}
						alt="main-banner"
						className="flex sm:hidden w-auto"
					/>
				</div>
				<div className="w-full lg:w-1/3">
					<img
						src={Secbanner}
						alt="sec-banner"
						className="hidden lg:flex w-auto"
					/>
					<img
						src={SecbannerTablet}
						alt="sec-banner"
						className="flex lg:hidden w-auto"
					/>
				</div>
			</div>
		</div>
	);
};

export default Mesin;
