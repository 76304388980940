import HeroImage from "../Image/hero/hero-image.png";
import HeroBg from "../Image/hero/hero-bg.png";
import { FaWhatsapp } from "react-icons/fa";
import { motion } from "framer-motion";

const HeroSection = () => {
	const layananBtnHandler = () => {
		const section = document.getElementById("layanan");
		if (section) {
			const headerHeight = 120;
			const offset = section.offsetTop - headerHeight;
			window.scrollTo({ top: offset, behavior: "smooth" });
		}
	};

	return (
		<div className="relative p-4 md:p-12 mx-auto w-5/6 md:w-3/4 my-8 rounded-2xl h-fit">
			<img
				src={HeroBg}
				alt="Logo"
				className="absolute top-0 left-0 w-full rounded-2xl z-0 h-full"
			/>
			<div className="flex justify-between items-center gap-4 md:gap-8 lg:gap-4 xl:gap-16">
				<div className="flex flex-col justify-between items-start z-10 gap-2 md:gap-6 xl:gap-12">
					<div className="text-white flex gap-1 border border-white py-1 px-2 lg:py-2 lg:px-4 rounded-full text-xs md:text-sm lg:text-base">
						<div className="font-semibold">ASTA</div>
						<div>Printing and Advertising</div>
					</div>
					<img
						src={HeroImage}
						alt="Logo"
						className="w-2/3 self-center z-10 flex md:hidden"
					/>

					<div className="flex flex-col gap-4 text-white justify-start items-start text-justify">
						<div className="font-bold text-xl md:text-2xl lg:text-4xl text-left">
							ONE-STOP PRINTING SERVICE
						</div>
						<div className="text-xs md:text-sm xl:text-base">
							Kami adalah one-stop printing service yang mampu memenuhi
							kebutuhan pelanggan dari awal sampai akhir, mulai dari konsultasi,
							cetak, potong, finishing, sampai tiba di lokasi pelanggan.
						</div>
					</div>
					<div className="flex flex-col lg:flex-row my-4 md:my-0 gap-3 md:gap-4 w-full">
						<motion.a
							whileTap={{ scale: 0.9 }}
							className="flex py-2 bg-salmon text-white rounded-2xl text-lg md:text-2xl gap-1 items-center justify-center w-full lg:w-1/2 md:max-w-64"
							href="https://wa.me/6285921923982"
							target="_blank"
						>
							<FaWhatsapp />
							<div className="font-semibold text-xs md:text-base">
								Pesan Sekarang
							</div>
						</motion.a>
						<motion.div
							whileTap={{ scale: 0.9 }}
							className="flex py-2 bg-white text-salmon rounded-2xl text-2xl gap-1 items-center justify-center w-full lg:w-1/2 md:max-w-64 cursor-pointer"
							onClick={layananBtnHandler}
						>
							<div className="font-semibold text-xs md:text-base">
								Lihat Layanan
							</div>
						</motion.div>
					</div>
				</div>
				<img src={HeroImage} alt="Logo" className="w-2/5 z-10 hidden md:flex" />
			</div>
		</div>
	);
};

export default HeroSection;
