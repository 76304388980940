import AstaLogoWhite from "../Image/logo/asta-logo-white.png";
import AicretsLogo from "../Image/logo/aicrets-logo.png";
import { IoMdMail } from "react-icons/io";
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";

const Footer = () => {
	return (
		<div className="bg-violet text-white w-full flex flex-col px-8 py-4">
			<img
				src={AstaLogoWhite}
				alt="logo"
				className="w-24 py-4 self-center md:self-start"
			/>
			<hr className="my-4" />
			<div className="flex flex-col md:flex-row justify-between gap-4 md:gap-0 py-4 text-center md:text-start">
				<div className="flex flex-col gap-1 md:gap-2 justify-start items-center md:items-start text-base">
					<div className="font-semibold">ASTA ADV & PRINT</div>
					<div>CV Andalan Semesta Tata Aksara</div>
					<div>
						JL. H. IPIN No. 27, Pondok Labu, Cilandak, Jakarta Selatan, DKI
						Jakarta
					</div>
				</div>
				<div className="flex flex-row md:flex-col gap-4 md:gap-2 justify-center md:justify-start items-center md:items-start text-base">
					<a
						className="flex gap-2 items-center"
						href="mailto:percetakanasta@gmail.com"
					>
						<IoMdMail className="text-3xl md:text-xl" />
						<div className="hidden md:flex">percetakanasta@gmail.com</div>
					</a>
					<a className="flex gap-2 items-center" href="tel:+62217272159">
						<FaPhoneAlt className="text-2xl md:text-xl" />
						<div className="hidden md:flex">021 727 2159</div>
					</a>
					<a
						className="flex gap-2 items-center"
						href="https://wa.me/6285921923982"
					>
						<FaWhatsapp className="text-3xl md:text-xl" />
						<div className="hidden md:flex">0859 2192 3982</div>
					</a>
				</div>
			</div>
			<div className="flex flex-col md:flex-row justify-start items-center gap-0 md:gap-2">
				<div>© Copyright 2024, CV Andalan Semesta Tata Aksara</div>
				<div className="hidden md:flex">|</div>
				<div className="flex items-center">
					<div>Developed by</div>
					<img src={AicretsLogo} alt="logo" className="w-24" />
				</div>
			</div>
		</div>
	);
};

export default Footer;
