import AstaLogoPurple from "../Image/logo/asta-logo-purple.png";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { FaBars, FaChevronRight, FaTimes, FaWhatsapp } from "react-icons/fa";

const Header = () => {
	const [nav, setNav] = useState(false);
	const [headerActiveTab, setHeaderActiveTab] = useState("");

	useEffect(() => {
		const section = document.getElementById(headerActiveTab);
		if (section) {
			const headerHeight = 120;
			const offset = section.offsetTop - headerHeight;
			window.scrollTo({ top: offset, behavior: "smooth" });
		}
		setHeaderActiveTab("");
	}, [headerActiveTab]);

	return (
		<div className="w-full py-6 px-4 md:px-6 lg:px-10 flex justify-between items-center">
			<div className="flex items-center font-inter gap-8 lg:gap-12 md:text-sm lg:text-base">
				{/* mobile */}
				<motion.div
					initial={{ scale: 0, opacity: 0 }}
					animate={{ scale: nav ? 1 : 0, opacity: nav ? 1 : 0 }}
					className="w-full absolute py-3 left-0 -top-3 z-50 origin-top-right"
				>
					<div className="flex flex-col bg-white border h-screen p-8 gap-4">
						<div className="flex justify-between mb-6 text-2xl">
							<motion.button
								whileTap={{ scale: 0.9 }}
								onClick={() => setNav(false)}
								className=""
							>
								<FaTimes />
							</motion.button>
						</div>
						<div className="text-2xl font-bold">Menu</div>
						<div
							className="flex justify-between items-center text-lg py-1"
							// href="#layanan"
							onClick={() => {
								setNav(false);
								setHeaderActiveTab("layanan");
							}}
						>
							<div className="">Layanan</div>
							<FaChevronRight />
						</div>
						<div
							className="flex justify-between items-center text-lg py-1"
							// href="#keunggulan"
							onClick={() => {
								setNav(false);
								setHeaderActiveTab("keunggulan");
							}}
						>
							<div className="">Keunggulan Kami</div>
							<FaChevronRight />
						</div>
						<div
							className="flex justify-between items-center text-lg py-1"
							// href="#tentang-kami"
							onClick={() => {
								setNav(false);
								setHeaderActiveTab("tentang-kami");
							}}
						>
							<div className="">Tentang kami</div>
							<FaChevronRight />
						</div>
						<div
							className="flex justify-between items-center text-lg py-1"
							// href="#pesan"
							onClick={() => {
								setNav(false);
								setHeaderActiveTab("pesan");
							}}
						>
							<div className="">Cara Order</div>
							<FaChevronRight />
						</div>
						<a
							className="flex py-2 px-4 bg-salmon text-white rounded-full text-2xl gap-2 items-center justify-center mt-10 self-center"
							href="https://wa.me/6285921923982"
							target="_blank"
							rel="noreferrer"
						>
							<div className="font-semibold text-lg">Hubungi Kami</div>
							<FaWhatsapp />
						</a>
					</div>
				</motion.div>
				{/* logo */}
				<a
					className="bg-white max-h-full lg:rounded-b-lg items-center"
					href="/"
				>
					<img
						src={AstaLogoPurple}
						alt="Logo"
						className="h-8 lg:h-10 pl-5 md:pl-10 lg:pl-0"
					/>
				</a>
				{/* pc */}
				<div className="hidden md:flex gap-4 lg:gap-14">
					<motion.a
						whileHover={{ scale: 1.1, opacity: 0.7 }}
						className="block font-medium cursor-pointer"
						// href="#layanan"
						onClick={() => setHeaderActiveTab("layanan")}
					>
						Layanan
					</motion.a>
					<motion.a
						whileHover={{ scale: 1.1, opacity: 0.7 }}
						className="block font-medium cursor-pointer"
						// href="#keunggulan"
						onClick={() => setHeaderActiveTab("keunggulan")}
					>
						Keunggulan Kami
					</motion.a>
					<motion.a
						whileHover={{ scale: 1.1, opacity: 0.7 }}
						className="block font-medium cursor-pointer"
						// href="#tentang-kami"
						onClick={() => setHeaderActiveTab("tentang-kami")}
					>
						Tentang Kami
					</motion.a>
					<motion.a
						whileHover={{ scale: 1.1, opacity: 0.7 }}
						className="block font-medium cursor-pointer"
						// href="#pesan"
						onClick={() => setHeaderActiveTab("pesan")}
					>
						Cara Order
					</motion.a>
				</div>
			</div>
			<motion.a
				whileHover={{ scale: 1.1 }}
				whileTap={{ scale: 0.9 }}
				className="hidden md:flex py-2 px-4 bg-salmon text-white rounded-full text-xl lg:text-2xl gap-2 items-center justify-center"
				href="https://wa.me/6285921923982"
				target="_blank"
			>
				<div className="font-semibold text-sm lg:text-base">Hubungi Kami</div>
				<FaWhatsapp />
			</motion.a>
			{/* hamburger */}
			<div className="md:hidden bg-white-100 ">
				<motion.button
					onClick={() => setNav(true)}
					whileTap={{ scale: 0.95 }}
					className="py-2 lg:py-5 h-full w-full px-5 md:px-10 flex items-center text-ungu-100 font-bold"
				>
					<FaBars />
				</motion.button>
			</div>
		</div>
	);
};

export default Header;
